.Toastify__toast {
  min-height: 64px !important;
  border-radius: 8px !important;
  padding: 6px 16px !important;
  overflow: hidden;
  font-size: 14px;

  .Toastify__progress-bar {
    height: 0.25rem !important;
  }
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}
.fadeOut {
  animation-name: fadeOut;
}

.animate {
  animation-duration: 800ms;
}
