//
//
// user.scss
//
// Place your own theme CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

/*-----------------------------------------------
|   Custom HTML attributes
-----------------------------------------------*/
/**
 * There is a valid HTML5 global attribute `autocapitalize` that allows to change the visual of the text
 * but not for lower casing. This will target a custom `lowercase` attribute and show its text to lowercase.
 */
[lowercase] {
  text-transform: lowercase;
}

/*-----------------------------------------------
|   Circle Avatar
-----------------------------------------------*/
.circle-avatar {
  vertical-align: middle;
  text-align: center;
  width: 8rem;
  height: 8rem;
  border-radius: 50%;

  &-initials {
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1;
    position: relative;
    top: 2rem;
    font-size: 4rem;
  }
}

/*-----------------------------------------------
|   Additional styles for tabs
-----------------------------------------------*/
.nav-tabs .nav-link {
  color: inherit;
}

.pointer-auto {
  cursor: auto;
}

/*-----------------------------------------------
|     Toastify override
-----------------------------------------------*/

.toastify-override {
  top: 3.75rem;
  right: 1rem;
}

/*-----------------------------------------------
|   Custom text colors
-----------------------------------------------*/

.text-color-inherit {
  color: inherit !important;
}

.progress-bar-cinderella {
  background: linear-gradient(90deg, #6870dc 7.81%, #efa6d9 96.62%);
}

.skeleton {
  &-columns > span {
    flex: 1;
    > .react-loading-skeleton {
      max-width: 70%;
    }
  }
  &-columns-alt {
    display: flex;
    .text-row {
      margin: 0 !important;
      max-width: 10%;
    }
  }
}

.custom-select {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
