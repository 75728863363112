$providers: (
  AWS: #ff9900,
  Azure: #008ad7,
  GCP: #34a951,
  Github: #333,
  GitHub: #333,
  Kubernetes: #326de6,
  Docker: #458ee6,
  Oracle: #f80000,
);

@each $provider, $value in $providers {
  .#{$provider} {
    background-color: $value !important;
  }
}
