/* The emerging W3C standard
   that is currently Firefox-only */
* {
  scrollbar-width: thin;
  scrollbar-color: gray lightgray;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-thumb {
  background: rgba(#232850, 0.35);
  border-radius: 20px;
}
