$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$headings-margin-bottom: 0.5rem !default;
$headings-font-weight: 600;
$h1-font-size: $font-size-base * 2 !default;
$h2-font-size: $font-size-base * 1.5 !default;
$h3-font-size: $font-size-base * 1.25 !default;
$h4-font-size: $font-size-base * 1.125 !default;
$h5-font-size: $font-size-base !default;
$h6-font-size: $font-size-base * 0.875 !default;

// Typography
//
// 1. Remove top margins from headings
//    By default, `<h1>`-`<h6>` all receive top and bottom margins. We nuke the top
//    margin for easier control within type scales as it avoids margin collapsing.

%heading {
  margin-top: 0; // 1
  margin-bottom: $headings-margin-bottom;
  font-weight: $headings-font-weight;
}

h1 {
  @extend %heading;
  font-size: $h1-font-size;
}

h2 {
  @extend %heading;
  font-size: $h2-font-size;
}

h3 {
  @extend %heading;
  font-size: $h3-font-size;
}

h4 {
  @extend %heading;
  font-size: $h4-font-size;
}

h5 {
  @extend %heading;
  font-size: $h5-font-size;
}

h6 {
  @extend %heading;
  font-size: $h6-font-size;
}
