.root {
  height: 100%;
  position: absolute !important;
  padding-bottom: 60px !important;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

.item {
  position: relative;
  user-select: none;
  display: flex;
  font-weight: 500;
  width: 190px;
  flex-wrap: nowrap;
  overflow: hidden;
  transition: width 0.2s;
  text-decoration: none !important;
  white-space: nowrap;
  background-color: #01023b;
  justify-content: space-between;
  cursor: pointer;
  color: white !important;
  padding: 10px 20px;

  &Parent {
    width: 190px;
    &:hover {
      background-color: #2f2b66 !important;
      width: 190px;
    }
    transition: width 0.2s;
  }

  &Label {
    display: flex;
    align-items: center;
  }

  &:hover {
    background-color: #211e46 !important;
    width: 190px;
  }

  &Inner {
    width: 190px !important;
    padding-left: 60px;

    &Collapse {
      padding-left: 70px;
    }

    &Wrapper {
      left: 0;
      width: auto;
    }
  }

  &Collapse {
    width: 64px;
  }

  &Icon {
    display: inline-flex;
    align-items: center;
    margin-right: 18px;
    transition: margin 0.2s;
    path {
      fill: white !important;
    }
    &Collapse {
      margin-right: 20px;
    }
  }
}

.itemActive {
  background-color: #000018 !important;
  &:hover,
  &:active {
    background-color: #1e1b3f !important;
    cursor: pointer;
  }
  transition: width background-color 0.2s;
}

.expandIcon {
  &Collapse {
    display: none;
  }
}
